@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('assets/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('assets/fonts/Satoshi-Variable.woff') format('woff'),
    url('assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Courier Prime';
  src: url('assets/fonts/CourierPrime-Regular.ttf') format('truetype');
  font-weight: 400;
}

html,
body,
div#root,
.screen-height {
  width: 100vw;
  height: stretch;
  position: fixed;
  overflow: hidden;
  background-color: black;
}

@media (max-width: 640px) and (orientation: portrait) {
  html,
  body,
  div#root,
  .disable-screen-height-mb {
    height: auto;
    position: relative;
    overflow: initial;
  }
}

@media (max-height: 640px) and (orientation: landscape) {
  html,
  body,
  div#root,
  .disable-screen-height-mb {
    height: auto;
    position: relative;
    overflow: initial;
  }
}
