.viewerGrid {
  grid-template-columns: 100%;
  grid-template-rows: 1fr 12rem;
}

/*for firefox*/
@-moz-document url-prefix() {
  @media (min-width: 641px) {
    .VodViewer {
      width: calc((100vw - 4rem) - 300px) !important;
      height: calc(((100vw - 4rem) - 300px) * 0.5625) !important;
      max-width: 1000px;
      max-height: 562.5px;
    }
  }
}
