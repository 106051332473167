.active {
  background: #fff;
  color: #000;
  svg path:not(:nth-last-child(3)) { 
    fill: #0016E9;
  }
}

.inactive {
  color: #fff;
  border: 1px solid #fff !important;
}
