// Workaround to correctly round the edges of the stream players on Safari.
// REF: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
.safariClip {
  position: relative;
  z-index: 1;
}

.scrollbar::-webkit-scrollbar {
  height: 0.5rem;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #334e68;
}
