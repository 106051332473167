.camera {
  height: 3rem;
  width: 3rem;
  fill: #fff;
}

div.selected {
  background-color: #0401c2;
  border: 4px solid #0016e9;

  &0 svg.bg {
    fill: none;
    stroke: black;
  }

  &1 svg.bg {
    fill: black;
  }

  &2 svg.bg {
    fill: #0016e9;
  }

  &3 {
    background-color: black;
    svg.bg {
      fill: #0016e9;
    }
  }
}

.offline {
  background-color: #010533;
}

.timerBar {
  transform: scaleX(0);
  transform-origin: left center;
  animation: timer 5s linear forwards;
}

@keyframes timer {
  to {
    transform: scaleX(1);
  }
}

.soundMuted {
  background-color: #334E68;
  border-radius: 100%;
  fill: #9FB3C8;
}

.playingSound {
  background-color: #0016e9;
  border-radius: 100%;
}