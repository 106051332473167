#sponsorshipContainer {
  margin-left: 193%;
}

@media only screen and (max-width: 768px) {
  #sponsorshipContainer {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1300px) {
  #sponsorshipContainer {
    margin-left: 50%;
  }
}

@media only screen and (max-width: 1700px) {
  #sponsorshipContainer {
    margin-left: 120%;
  }
}
