.error {
  animation: shake 0.25s;
}

@keyframes shake {
  0% {
    transform: translate(2px, 0);
  }
  25% {
    transform: translate(-2px, 0);
  }
  50% {
    transform: translate(2px, 0);
  }
  75% {
    transform: translate(-2px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loading {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    stroke: white;
  }
  50% {
    stroke: grey;
  }
  100% {
    stroke: white;
  }
}
